import { api } from 'utils/axios';

export interface UpdateBankAccountLimitRequestParams {
  id: number;
  limitMinIn: number;
}

export async function updateBankAccountLimitRequest(params: UpdateBankAccountLimitRequestParams) {
  await api.post('/bank-accounts/limit', params);
}
