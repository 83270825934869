export const getLayoutStyles = (colorScrollbar: string) => ({
  sider: {
    normal: {
      textAlign: 'left',
      width: '200px',
      height: '100vh',
      position: 'fixed',
      zIndex: '5',
      overflowY: 'auto',
      overflowX: 'hidden',
    } as React.CSSProperties,
    narrow: {
      textAlign: 'left',
      width: '50px',
      height: '100vh',
      position: 'fixed',
      zIndex: '11',
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '0px',
      left: '0px',
      paddingTop: '60px',
      transition: 'left 1s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    } as React.CSSProperties,
  },
  layout: {
    normal: {
      minWidth: '1340px',
      maxWidth: '1600px',
      margin: '0 auto',
      scrollbarColor: colorScrollbar,
      position: 'relative',
    } as React.CSSProperties,
    narrow: {
      minWidth: '1150px',
      maxWidth: '1339px',
      margin: '0 auto',
      scrollbarColor: colorScrollbar,
      position: 'relative',
    } as React.CSSProperties,
  },
  main: {
    normal: {
      marginLeft: '200px',
      minWidth: '1140px',
      scrollbarColor: colorScrollbar,
    } as React.CSSProperties,
    narrow: {
      marginLeft: '0',
      minWidth: '100%',
      scrollbarColor: colorScrollbar,
    } as React.CSSProperties,
  },
  content: {
    padding: '28px 10px 28px 28px',
    scrollbarColor: colorScrollbar,
  } as React.CSSProperties,
  scrollBar: {
    scrollbarWidth: 'thin',
    scrollbarColor: colorScrollbar,
  } as React.CSSProperties,
});
