import React, { FC, useEffect, useState } from 'react';
import { InputNumber } from 'components/Input';
import { useUpdateBankAccountLimitRequest } from 'modules/bankAccounts/hooks';
import { App } from 'antd';

interface Props {
  bankAccountId: number;
  limitMinIn: number;
}

export const LimitMinInInput: FC<Props> = ({ limitMinIn, bankAccountId }) => {
  const [value, setValue] = useState<number>(limitMinIn);

  const { updateBankAccountLimit, isUpdatingBankAccountLimit } = useUpdateBankAccountLimitRequest();

  const { message } = App.useApp();

  useEffect(() => {
    setValue(limitMinIn);
  }, [limitMinIn]);

  async function handleSubmit() {
    try {
      await updateBankAccountLimit({ id: bankAccountId, limitMinIn: value });

      message.success('Лимит успешно обновлен');
    } catch {
      message.error('Не удалось обновить лимит');
      setValue(limitMinIn);
    }
  }

  return (
    <InputNumber
      value={value}
      onChange={(value) => setValue(value as number)}
      disabled={isUpdatingBankAccountLimit}
      onBlur={handleSubmit}
      onKeyDown={(e) => {
        if (e.key !== 'Enter') {
          return;
        }

        handleSubmit();
      }}
    />
  );
};
