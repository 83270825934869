import { FormFieldSchema } from 'components/UnifiedForm/types/fieldsSchema.interface';
import { useBankAccountsTypesFromServer, useBanksFromServer } from 'hooks/useDataFromServer';
import { UpdateBankAccountRequestParams } from 'modules/bankAccounts/api/updateBankAccount.request';

export const fieldsSchema: FormFieldSchema<UpdateBankAccountRequestParams>[] = [
  {
    name: 'id',
    type: 'input',
    hidden: true,
  },
  {
    name: 'bankId',
    type: 'lazy-select',
    placeholder: 'Банк',
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-select',
  },
  {
    name: 'accountNumber',
    type: 'input',
    addonBefore: 'Номер счета',
    placeholder: '00000000000000000000',
  },
  {
    name: 'bic',
    type: 'input',
    addonBefore: 'БИК',
    placeholder: '000000000',
  },
  {
    name: 'phone',
    type: 'input',
    addonBefore: 'Номер телефона',
    placeholder: '+7 000 000 0000',
  },
  {
    name: 'type',
    type: 'lazy-radio',
    title: 'Тип счета',
    optionType: 'button',
    cacheKey: 'bankAccountsTypes-radio',
    className: 'mb-0',
    useDataHook: useBankAccountsTypesFromServer,
    dataTransformer: (item) => ({ label: item.name, value: item.id }),
  },
];
