import useSWRMutation from 'swr/mutation';
import { updateBankAccountLimitRequest, UpdateBankAccountLimitRequestParams } from 'modules/bankAccounts/api';

export function useUpdateBankAccountLimitRequest() {
  const { trigger, isMutating, data, error } = useSWRMutation(
    'update-bank-account-limit-request',
    (_, { arg }: { arg: UpdateBankAccountLimitRequestParams }) => updateBankAccountLimitRequest(arg),
  );

  return {
    updateBankAccountLimit: trigger,
    isUpdatingBankAccountLimit: isMutating,
    updateBankAccountLimitData: data,
    updateBankAccountLimitError: error,
  };
}
